import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import DeviceErrorCodesEnum from 'common/enums/DeviceErrorCodesEnum';
import moment from "moment";
import DevicePlaybackEnum from "common/enums/DevicePlaybackEnum";

const useStyles = makeStyles((theme) => styles(theme));

const StatusAndVolumeCard = (props) => {
    const classes = useStyles();
    const {
        intl,
        player,
        status,
        isEnabled,
        lastHeartbeat,
    } = props;

    const renderDeviceStatus = () => {
        return <>
            <br />
            <Grid container spacing={2}>

                <Grid item sm={12}>
                    {intl.formatMessage({ id: "storeDetail.deviceStatusCard.status" })}
                    {(status === DeviceErrorCodesEnum.OFFLINE.text) ?
                        <Box color="error.main" className={classes.floatRight}>
                            {status}
                        </Box>
                        :
                        <Box color="success.main" className={classes.floatRight}>
                            {status}
                        </Box>
                    }
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "storeDetail.deviceStatusCard.isEnabled" })}
                    {(!isEnabled) ?
                        <Box color="error.main" className={classes.floatRight}>
                            {intl.formatMessage({ id: "deviceDetail.deviceStatusCard.deviceDeactivated" })}
                        </Box>
                        :
                        <Box color="success.main" className={classes.floatRight}>
                            {intl.formatMessage({ id: "deviceDetail.deviceStatusCard.deviceActivated" })}
                        </Box>
                    }
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "storeDetail.deviceStatusCard.lastHeartbeat" })}
                    <Box className={classes.floatRight}>
                        {lastHeartbeat
                            ? (
                                <span className={classes.floatRight}>
                                    {moment(lastHeartbeat).format("DD.MM.YYYY HH:mm:ss")}
                                </span>
                            ) : (
                                <span> - </span>
                            )
                        }
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "deviceDetail.status.playbackStatus" })}
                    {(player === DevicePlaybackEnum.PLAYING)
                        ? <Box color="success.main" className={classes.floatRight}>
                            {intl.formatMessage({ id: "deviceDetail.devicePlayback.online" })}
                        </Box>
                        : <Box color="error.main" className={classes.floatRight}>
                            {intl.formatMessage({ id: "deviceDetail.devicePlayback.noplay" })}
                        </Box>
                    }
                </Grid>
            </Grid>
        </>
    }

    return (
        <>

            <IconCard
                title={intl.formatMessage({ id: "storeDetail.deviceStatusCard.title" })}
                className={classes.cardStyle}
                icon={<EqualizerIcon />}
            >
                {renderDeviceStatus()}
            </IconCard>
        </>
    );
};
export default withRouter(injectIntl(StatusAndVolumeCard));
